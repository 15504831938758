<template>
    <workspace-wrapper>
        <template #header>
            <default-slot-header :bet="stateData.currentSpinResult.bet" :lines="stateData.currentSpinResult.linesCount"
                :stake="stateData.currentSpinResult.stake" />
            <free-spins-slot-header v-if="stateData.currentSpinResult.isFreeSpin"
                v-bind="stateData.currentSpinResult.bonusFreeSpins" />
        </template>

        <template #workspace>
            <default-slot-workspace :layout="getLayout()" :symbols-path="symbolsPath" />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../Common/WorkspaceWrapper";
import DefaultSlotHeader from "./Common/Header/DefaultSlotHeader";
import FreeSpinsSlotHeader from "./Common/Header/FreeSpinsSlotHeader";
import DefaultSlotWorkspace from "./Common/DefaultSlotWorkspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        balanceBefore: null,
                        bet: null,
                        linesCount: null,
                        stake: null,
                        isExtra: null,
                        layout: null,
                        isFreeSpin: null,
                        bonusFreeSpins: {
                            totalWin: null,
                            totalFreeSpins: null,
                            currentFreeSpin: null,
                            freeSpinsAwardedCount: null
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            symbolsPath: 'bookofradeluxe6'
        }
    },
    methods: {
        getLayout() {
            const layout = this.stateData.currentSpinResult.layout;
            if (this.stateData.currentSpinResult.isExtra === false) {
                layout.pop();
                return layout;
            } else {
                return layout;
            }
        }
    },

    components: {
        WorkspaceWrapper,
        DefaultSlotHeader,
        DefaultSlotWorkspace,
        FreeSpinsSlotHeader
    }
}
</script>

<style scoped></style>
